let index = function ($) {

    let $connection = $body.find('.connection');
    let $main = $body.find('.main');

    let $content = $body.find('#module #connection .items');
    let $contentTop = $content.find('.items-top');
    let $contentBottom = $content.find('.items-bottom');

    let $itemsTop = $contentTop.children();
    let $itemsBottom = $contentBottom.children();

    let $itemTopConnect = $itemsTop.first();
    let $itemBottomConnect = $itemsBottom.first();

    let connect = false;

    /**
     * ifConnect
     */
    let ifConnect = function (dfd) {
        connect = (config.connect) ? config.connect : false;
        return dfd.resolve(connect);
    };

    /**
     * initSlide
     */
    let initSlide = function () {
        let dfdConnect = $.Deferred();
        ifConnect(dfdConnect);
        dfdConnect.then(function (data) {
            if (data) { // Si l'utilisateur est connecté
                let dfdLocation = $.Deferred();
                getLocation(dfdLocation);
                dfdLocation.then(function () {
                    // Intro
                    if (config && config.location !== '' && config.type === 'I') {
                        if (config.location[0] === 0) {
                            config.location[0] = config.location[0] + 1
                        }
                        let $itemTopLoad = $itemsTop.eq(config.location[0]);
                        let $itemBottomLoad = $itemsBottom.eq(config.location[0]);
                        showItem($itemTopLoad);
                        showItem($itemBottomLoad);
                    }
                    // Modules
                    if (config && config.location !== '' && config.type !== 'I') {
                        startModules();
                    }
                });

            } else { // Si l'utilisateur est déconnecté
                // On affiche le premier item si l'utilisateur n'est pas connecté
                showItem($itemTopConnect);
                showItem($itemBottomConnect);
            }
        });
    };

    /**
     * Affiche un item
     */
    let showItem = function ($item) {
        $item.show().css({
            display: 'flex',
            opacity: 0
        }).animate({
            'opacity': 1
        }, 1000);
    };

    /**
     * runQuiz
     */
    let initNav = function () {
        // Slide suivant
        $content.on('click', '.btn-next', function (e) {
            e.preventDefault();

            let index = $(this).closest('.bottom').index();
            let $itemBottomNow = $itemsBottom.eq(index);
            let $itemTopNext = $itemsTop.eq(index).next();
            let $itemBottomNext = $itemsBottom.eq(index).next();

            // Si c'est un slide quizz et si l'utilisateur a répondu à la question
            let check = false;
            let valCheck = '';
            if ($itemBottomNow.hasClass('quiz')) {
                let $radios = $itemBottomNow.find('input[type="radio"]');
                $radios.each(function () {
                    if (this.checked) {
                        check = true;
                        valCheck = this.value;
                    }
                });
                if (!check) return false;
                // saveExercices(0, 0, index, valCheck);
            }
            // Changement de slide
            if ($itemBottomNow.hasClass('quiz') !== $itemBottomNext.hasClass('quiz')) {
                $contentTop.find('.top:visible').fadeOut(500, function () {
                    showItem($itemTopNext);
                });
            }
            $contentBottom.find('.bottom:visible').fadeOut(500, function () {
                showItem($itemBottomNext);
            });
            let dfd = $.Deferred();
            saveProgression(0, 0, $itemBottomNext.index(), dfd, true);
            dfd.then(function () {
                updateLocation($itemBottomNext.index(), -1, -1, -1); // Mise à jour de la position dans le module
            });
        });

        // Slide précédent
        $content.on('click', '.btn-prev', function (e) {
            e.preventDefault();

            let index = $(this).closest('.bottom').index();
            let $itemBottomNow = $itemsBottom.eq(index);
            let $itemTopPrev = $itemsTop.eq(index).prev();
            let $itemBottomPrev = $itemsBottom.eq(index).prev();

            // Changement de slide
            if ($itemBottomNow.hasClass('quiz') !== $itemBottomPrev.hasClass('quiz')) {
                $contentTop.find('.top:visible').fadeOut(500, function () {
                    showItem($itemTopPrev);
                });
            }
            $contentBottom.find('.bottom:visible').fadeOut(500, function () {
                showItem($itemBottomPrev);
            });

            let dfd = $.Deferred();
            saveProgression(0, 0, $itemBottomPrev.index(), dfd, true);
            dfd.then(function () {
                updateLocation($itemBottomPrev.index(), -1, -1, -1); // Mise à jour de la position dans le module
            });
        });
    };

    /**
     * startModules
     */
    let startModules = function (event = false) {
        if (event) {
            $contentBottom.on('click', '.btn-start', function (e) {
                e.preventDefault();
                showModules(true);
            });
            config.type = "M";
        } else {
            showModules();
        }

        function showModules(statusUpdate = false) {
            if (statusUpdate) updateLocation(lengthSlideIntro, 1); // Mise à jour de la position dans le module
            $content.fadeOut(500, function () {
                $connection.fadeOut(0, function () {
                    $main.css('display', 'flex');
                });
            });
        }
    };

    /**
     * constructMenu
     */
    let constructMenu = function () {
        $.getJSON("json/modules.json").done(function (json) {
            if (json && Object.keys(json).length > 0) {
                let $navUl = $body.find('#module > section.main > .menu .nav ul');
                let template, compiledTemplate;
                let html = '';
                $.each(json, function (k, moduleJson) {
                    template = document.getElementById('tpl-item-menu').innerHTML;
                    compiledTemplate = doT.template(template);
                    html += compiledTemplate(moduleJson);
                    if (k == Object.keys(json).length) {
                        $(html).appendTo($navUl);
                    }
                });
            }
        });
    };

    /**
     * Sauvegarde exercice intro
     */
    let quizIntro = function () {
        $body.on('change', '#connection input[type="radio"]', function () {
            let name = $(this).attr('name');
            if (!exercices['0_' + name]) {
                exercices['0_' + name] = {};
            }
            exercices['0_' + name].type = 'qcu';
            exercices['0_' + name].val = $(this).val();
            saveExercices();
        });
    };


    return {
        init: function (dfd) {
            initSlide();
            initNav();
            startModules('click');
            constructMenu();
            quizIntro();
            return dfd.resolve();
        }
    };
}(jQuery);